import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import Blockquote from '../components/Blockquote';
import ButtonAsAnchor from '../components/Button/ButtonAsAnchor';

import { AboutQuery } from '../../graphql-types';
import Checklists from '../components/Checklists';
import SEO from '../containers/SEO/SEO';

import './o-about-section.scss';
import './o-suggested-checklists.scss';
import AboutImage from '../images/about.png';

interface Props {
  data: AboutQuery;
}

const About: FC<Props> = ({ data }) => (
  <Layout>
    <SEO title="About" />

    <div className="row o-about-section">
      <section className="col col--md-10 col--lg-8">
        <div className="u-text-align-center u-padding-bottom-xlarge">
        <img
              src={AboutImage}
              alt="About checklist"
            />
        </div>

        <h1 className="h2 u-margin-bottom">
          <div className="u-color-secondary">Teknovol</div> Checklist Nedir
        </h1>

        <p>
          Otomatikleştirilebilen her şeyi otomatikleştirmeyi seviyoruz. Otomatikleştiremediğimiz şeyler için
           (şimdilik) bir protokolümüz var. Bu protokoller, iş akışımızı standart hale getirir, böylece Teknovol içinde herkes
           bir standart üzerinde çalışır.
        </p>

        <p>
          7 yılı aşkın süredir farklı büyüklüklerde projeler tasarlıyor, geliştiriyor ve yönetiyoruz.
          Farklı ölçekte projeler üzerinde çalışmak, sonraki projelerde daha güzel başarılara ulaşmamızı sağlayan paha biçilmez deneyimler kazandırdı. İş akışımızı, çalışma şeklimizi hatta çalışma saatlerimizi bile güncelledik. Teknolojide devam eden büyümeye uyum sağlamaya çalışıyoruz ve protokollerimizi güncel tutmakta kararlıyız.
        </p>

        <p>
          Lütfen bir kontrol listesi önermek veya mevcut bir kontrol listesi üzerinde iyileştirme önermekten çekinmeyin. Her öneriyi dikkate alacağız. İhtiyaçlarımıza ve çalışma şeklimize uygun olduğunu düşündüklerimizi uygulayacağız.
        </p>

        <ButtonAsAnchor
          href="https://www.teknovol.com.tr"
          className="u-margin-top-xlarge u-width-100%@sm-down"
          target="_blank"
          rel="noopener noreferrer"
        >
          teknovol.com.tr
        </ButtonAsAnchor>
      </section>
    </div>
    <hr className="u-margin-ends-0 u-color-primary-900" />
    <section className="o-suggested-checklists">
      <h2 className="u-margin-bottom">Gitmeden önce bu kontrol listelerine göz atın!</h2>
      <div className="row">
        <div className="col col--md-9 col--lg-6">
          <Blockquote>
            Herkesin bir kontrol listesi önermesi için bir protokol geliştirdik. Hemen hemen her şey için bir protokolümüz olduğunu söylemiştik!{' '}
            <span role="img" aria-labelledby="rocket">
              🚀
            </span>
          </Blockquote>
        </div>
      </div>
      <Checklists items={data.suggestions.nodes} className="u-margin-top-medium" />
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query About {
    suggestions: allMarkdownRemark(
      filter: { fields: { slug: { in: ["checklist-checklist", "improvement-checklist"] } } }
    ) {
      nodes {
        ...Checklist
      }
    }
  }
`;

export default About;
